import React, { useState } from 'react';
import { Typography, Grid, Box, Container, TextField, Button } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar';
// import ReCAPTCHA from 'react-google-recaptcha'; // Commented out for now

// Styled components to match the blue gradient from the home page
const ContactSection = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #3b82f6 30%, #9333ea 90%)',  // Updated gradient to match Home page
  color: '#000',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: '50px 0',
  width: '100%',
  boxSizing: 'border-box',
  overflowX: 'hidden',
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  fontSize: '1.2rem',
  padding: theme.spacing(1.5, 6),
  borderRadius: '50px',
  backgroundColor: '#9333ea',
  color: '#fff',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  boxShadow: '0px 8px 15px rgba(147, 51, 234, 0.5)',
  '&:hover': {
    backgroundColor: '#7e22ce',
    boxShadow: '0px 12px 20px rgba(147, 51, 234, 0.7)',
  },
}));

// Framer Motion animation variants
const fadeInVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.7, ease: 'easeOut', staggerChildren: 0.3 } },
};

const fieldFadeInVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeOut' } },
};

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [statusMessage, setStatusMessage] = useState('');
  const [statusType, setStatusType] = useState('');

  // Handle form data changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiURL = '/send-email';  // Relative URL for backend API

    try {
      const response = await fetch(apiURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData }),
      });

      if (response.ok) {
        setStatusMessage('Your message has been sent! Thank you for contacting us.');
        setStatusType('success');
        setFormData({ name: '', email: '', message: '' });
      } else {
        setStatusMessage('Sorry, there was an issue sending your message. Please try again.');
        setStatusType('error');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setStatusMessage('Sorry, there was an issue sending your message. Please try again.');
      setStatusType('error');
    }
  };

  return (
    <ContactSection>
      <Navbar />

      <Container maxWidth="md" sx={{ textAlign: 'center' }}>
        {/* Page Heading with animation */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeInVariants}
        >
          <Typography 
            variant="h3" 
            component="h1" 
            sx={{ fontSize: '60px', fontWeight: 'bold', marginBottom: '20px', color: '#fff' }}  // White text for better contrast
          >
            Contact Us
          </Typography>
        </motion.div>

        {/* Form Section */}
        <motion.div
          initial="hidden"
          animate="visible"
          transition={{ delay: 0.2, duration: 0.7, ease: 'easeOut' }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              backgroundColor: 'white',
              borderRadius: '12px',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              padding: '30px',
              maxWidth: '700px',
              margin: '0 auto',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <motion.div variants={fieldFadeInVariants}>
                  <TextField
                    fullWidth
                    label="Your Name"
                    name="name"
                    variant="outlined"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    sx={{ fontFamily: 'inherit' }}
                  />
                </motion.div>
              </Grid>
              <Grid item xs={12}>
                <motion.div variants={fieldFadeInVariants}>
                  <TextField
                    fullWidth
                    label="Your Email"
                    name="email"
                    type="email"
                    variant="outlined"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    sx={{ fontFamily: 'inherit' }}
                  />
                </motion.div>
              </Grid>
              <Grid item xs={12}>
                <motion.div variants={fieldFadeInVariants}>
                  <TextField
                    fullWidth
                    label="Your Message"
                    name="message"
                    variant="outlined"
                    multiline
                    rows={6}
                    value={formData.message}
                    onChange={handleChange}
                    required
                    sx={{ fontFamily: 'inherit' }}
                  />
                </motion.div>
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <motion.div variants={fieldFadeInVariants}>
                  <SubmitButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Send Message
                  </SubmitButton>
                </motion.div>
              </Grid>
            </Grid>

            {/* Status Message */}
            {statusMessage && (
              <Typography
                variant="body1"
                component="p"
                sx={{
                  marginTop: '20px',
                  color: statusType === 'success' ? 'green' : 'red',
                }}
              >
                {statusMessage}
              </Typography>
            )}

            {/* Google reCAPTCHA (Commented out for testing) */}
            {/* 
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <ReCAPTCHA
                sitekey="6LfqN1IqAAAAAOHbm03De7LHMVlb_XGyuGRgUq0G"
                onChange={handleRecaptcha}
              />
            </Grid>
            */}
          </Box>
        </motion.div>
      </Container>
    </ContactSection>
  );
}

export default ContactUs;
