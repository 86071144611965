import React from 'react';
import { Container, Box, Grid, Typography, Button } from '@mui/material';
import CloudIcon from '@mui/icons-material/Cloud';
import InsightsIcon from '@mui/icons-material/Insights';
import BoltIcon from '@mui/icons-material/Bolt';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar'; // Import Navbar component

// Hero section styling with no extra margin
const HeroSection = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #3b82f6 30%, #9333ea 90%)',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  width: '100%',
  boxSizing: 'border-box',
  overflowX: 'hidden',
}));

const CallToAction = styled(Button)(({ theme }) => ({
  fontSize: '1.3rem',
  padding: theme.spacing(1.8, 7),
  borderRadius: '50px',
  backgroundColor: '#9333ea',
  color: '#fff',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  boxShadow: '0px 8px 15px rgba(147, 51, 234, 0.5)',
  '&:hover': {
    backgroundColor: '#7e22ce',
    boxShadow: '0px 12px 20px rgba(147, 51, 234, 0.7)',
  },
}));

const fadeInVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.7, ease: 'easeOut' } },
};

const hoverVariants = {
  hover: { scale: 1.2, transition: { duration: 0.3, ease: 'easeInOut' } },
};

function Home() {
  return (
    <Box sx={{ margin: 0, padding: 0, width: '100%' }}>
      {/* Navbar */}
      <Navbar /> {/* Use Navbar */}

      {/* Hero Section */}
      <HeroSection>
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: 'easeOut' }}
        >
          <Typography variant="h2" component="h1" gutterBottom>
            Empower Your Customer Relationships
          </Typography>
          <Typography variant="h5" sx={{ mb: 5 }}>
            Discover AI-powered customer management solutions tailored for modern businesses.
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Our website is launching soon—stay informed!
          </Typography>
          <CallToAction variant="contained" size="large" href="/contact-us">
            Get Notified
          </CallToAction>
        </motion.div>
      </HeroSection>

      {/* Main Content */}
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Box sx={{ my: 5, textAlign: 'center' }}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, ease: 'easeOut' }}
          >
            <Typography variant="h4" gutterBottom sx={{ color: '#1f2937', fontWeight: 'bold' }}>
              Why Choose CRM Tooling?
            </Typography>
          </motion.div>

          {/* Icon Section for Key Benefits */}
          <Grid container spacing={6} sx={{ mt: 5 }}>
            <Grid item xs={12} sm={4}>
              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
                whileHover="hover"
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <motion.div variants={hoverVariants} whileHover="hover">
                    <CloudIcon color="primary" sx={{ fontSize: 100 }} />
                  </motion.div>
                  <Typography variant="h6" gutterBottom>
                    Cloud-Based
                  </Typography>
                  <Typography color="textSecondary">Access your CRM anywhere, anytime.</Typography>
                </Box>
              </motion.div>
            </Grid>

            <Grid item xs={12} sm={4}>
              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
                transition={{ delay: 0.2 }}
                whileHover="hover"
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <motion.div variants={hoverVariants} whileHover="hover">
                    <InsightsIcon color="primary" sx={{ fontSize: 100 }} />
                  </motion.div>
                  <Typography variant="h6" gutterBottom>
                    AI-Powered Customer Data
                  </Typography>
                  <Typography color="textSecondary">Intelligent customer analytics.</Typography>
                </Box>
              </motion.div>
            </Grid>

            <Grid item xs={12} sm={4}>
              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
                transition={{ delay: 0.4 }}
                whileHover="hover"
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <motion.div variants={hoverVariants} whileHover="hover">
                    <BoltIcon color="primary" sx={{ fontSize: 100 }} />
                  </motion.div>
                  <Typography variant="h6" gutterBottom>
                    Streamlined Operations
                  </Typography>
                  <Typography color="textSecondary">
                    Simplify your workflow with automation.
                  </Typography>
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Home;

