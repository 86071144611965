import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    background: 'linear-gradient(135deg, #3b82f6 30%, #9333ea 90%)',
    boxShadow: 'none',
    position: 'fixed', // Keep the navbar fixed at the top
    top: 0, // Ensure it's positioned at the very top
    width: '100%', // Full width
    zIndex: theme.zIndex.appBar, // Ensure it's on top of other elements
}));

function Navbar() {
  return (
    <StyledAppBar>
      <Toolbar>
        {/* Make CRM Tooling clickable */}
        <Typography
          variant="h6"
          component="a"
          href="/"
          sx={{
            flexGrow: 1,
            fontWeight: 'bold',
            textDecoration: 'none',
            color: '#fff',
          }}
        >
          CRM Tooling
        </Typography>
        <Button color="inherit" sx={{ fontWeight: 'bold', color: '#fff' }} href="/">
          Home
        </Button>
        <Button color="inherit" sx={{ fontWeight: 'bold', color: '#fff' }} href="/contact-us">
          Contact
        </Button>
      </Toolbar>
    </StyledAppBar>
  );
}

export default Navbar;
